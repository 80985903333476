import React, { useContext, useEffect, useState } from "react"
import SiteContext from "../context/SiteContext"

import { graphql } from "gatsby"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Header,
  TitleNews,
  Text,
  Link,
  Video,
  FullWidthMedia,
  Image,
  MediaSlice,
  HTML,
  Script
} from "../components/slices"

import Related from "../components/related/related-grid"

const axios = require("axios")

const Container = styled.div`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "#eeede9"};
  padding-bottom: 0.01rem;
`

const renderSlice = (slice, backgroundColor, textColor, videoId) => {
  switch (slice.__typename || slice.typeHandle) {
    case "Craft_slicesNews_text_BlockType":
    case "text":
      return (
        <Text
          data={slice}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      )
      break
    case "Craft_slicesNews_link_BlockType":
    case "link":
      return (
        <Link
          data={slice}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      )
      break
    case "Craft_slicesNews_video_BlockType":
    case "video":
      return (
        <Video
          data={slice}
          backgroundColor={backgroundColor}
          textColor={textColor}
          id={videoId}
        />
      )
      break
    case "Craft_slicesNews_fullWidthMedia_BlockType":
    case "fullWidthMedia":
      return (
        <FullWidthMedia
          data={slice}
          text={slice.textField}
          textColor={textColor}
        />
      )
      break
    case "Craft_slicesNews_image_BlockType":
    case "image":
      return <Image data={slice} />
      break
    case "Craft_slicesNews_mediaSlice_BlockType":
    case "mediaSlice":
      return (
        <MediaSlice
          data={slice}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      )
      break
      case "Craft_slicesNews_html_BlockType":
        case "html":
          return (
            <HTML
              data={slice}
            />
          )
          break
      case "Craft_slicesNews_script_BlockType":
        case "script":
          return (
            <Script
              data={slice}
              />
            )
        break          
    default:
      return null
  }
}

export default props => {
  let [data, setData] = useState(props.data.craft.entry)
  let [colors, setColors] = useState({
    backgroundColor: props.data.craft.entry.backgroundColor
      ? props.data.craft.entry.backgroundColor
      : "#eeede9",
    textColor: props.data.craft.entry.textColor
      ? props.data.craft.entry.textColor
      : "#272727",
  })

  let context = useContext(SiteContext)
  let videoId = -1

  useEffect(() => {
    context.changeColor(colors.textColor)

    let hashes = window.location.search
      .slice(window.location.search.indexOf("?") + 1)
      .split("&")
    let params = {}
    hashes.map(hash => {
      let [key, val] = hash.split("=")
      params[key] = decodeURIComponent(val)
    })

    if (params.token) {
      axios({
        url: "https://hbfff.frb.io/api",
        method: "post",
        params: {
          token: `${params.token}`,
          "x-craft-live-preview": params["x-craft-live-preview"],
        },
        data: {
          query: `
          query {
              entry(id: ${props.pageContext.id}) {
                ... on news_article_Entry {
                  id
                  uri
                  title
                  titleToggle
                  slug
                  date
                  textColor
                  backgroundColor
                  header {
                    ... on header_media_BlockType {
                      image {
                        url
                      }
                      video {
                        url
                      }
                    }
                  }
                  slicesNews {
                    ... on slicesNews_text_BlockType {
                      typeHandle
                      text
                    }
                    ... on slicesNews_link_BlockType {
                      typeHandle
                      linkText
                      linkField
                    }
                    ... on slicesNews_video_BlockType {
                      typeHandle
                      videoEmbedId
                      linkText
                      linkField
                      videoPlatform
                    }
                    ... on slicesNews_fullWidthMedia_BlockType {
                      typeHandle
                      textField
                      video {
                        url
                      }
                      image {
                        url
                      }
                    }
                    ... on slicesNews_image_BlockType {
                      typeHandle
                      image {
                        url
                        ... on images_Asset {
                          url
                        }
                      }
                    }
                    ... on slicesNews_mediaSlice_BlockType {
                      typeHandle
                      images {
                        url
                        height
                        width
                      }
                      titleField
                      textField
                      leftAlign
                      buttonOneText
                      buttonTwoText
                      buttonOneLink
                      buttonTwoLink
                    }
                    ... on slicesNews_html_BlockType {
                      typeHandle
                      html
                    }
                    ... on slicesNews_script_BlockType {
                      typeHandle
                      script
                    }                    
                  }
                }
              }
            }
            `,
        },
        headers: {
          Authorization: `Bearer EQgYRtC5gHijqoBTQ-uQRmykBleFdtDe`,
        },
      }).then(result => {
        setData(null)
        setData(result.data.data.entry)

        context.changeColor(result.data.data.entry.textColor)

        setColors({
          backgroundColor: result.data.data.entry.backgroundColor,
          textColor: result.data.data.entry.textColor,
        })
      })
    }
  }, [])

  return data !== null ? (
    <Layout
      backgroundColor={colors.backgroundColor}
      textColor={colors.textColor}
      overrideBackgroundColor="#dad9d5"
      overrideTextColor="#272727"
    >
      <SEO title={data.title} />
      <Container backgroundColor={colors.backgroundColor}>
        <Header data={data.header[0]} />
        {data.titleToggle ? (
          <TitleNews
            title={data.title}
            date={data.date}
            backgroundColor={colors.backgroundColor}
            textColor={colors.textColor}
          />
        ) : null}
        {data.slicesNews.map(slice => {
          if (
            slice.__typename === "Craft_slicesNews_video_BlockType" ||
            slice.typeHandle === "video"
          ) {
            videoId += 1
          }

          return renderSlice(
            slice,
            colors.backgroundColor,
            colors.textColor,
            videoId
          )
        })}
        {/* <Related
          data={related}
          backgroundColor={"#19171a"}
          textColor={entry.textColor}
        /> */}
      </Container>
    </Layout>
  ) : null
}

export const query = graphql`
  query($id: [Craft_QueryArgument]) {
    craft {
      entry(id: $id) {
        ... on Craft_news_article_Entry {
          id
          uri
          title
          titleToggle
          slug
          date
          textColor
          backgroundColor
          header {
            ... on Craft_header_media_BlockType {
              image {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              video {
                url
              }
            }
          }
          slicesNews {
            ... on Craft_slicesNews_text_BlockType {
              text
            }
            ... on Craft_slicesNews_link_BlockType {
              linkText
              linkField
            }
            ... on Craft_slicesNews_video_BlockType {
              videoEmbedId
              linkText
              linkField
              videoPlatform
            }
            ... on Craft_slicesNews_fullWidthMedia_BlockType {
              textField
              video {
                url
              }
              image {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on Craft_slicesNews_image_BlockType {
              image {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on Craft_slicesNews_mediaSlice_BlockType {
              images {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              titleField
              textField
              leftAlign
              buttonOneText
              buttonTwoText
              buttonOneLink
              buttonTwoLink
            }
            ... on Craft_slicesNews_html_BlockType {
              html
            }
            ... on Craft_slicesNews_script_BlockType {
              script
            }            
          }
        }
      }
    }
  }
`
