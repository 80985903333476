import React from "react"

import styled from "styled-components"

import { Link } from "gatsby"

import Img from "gatsby-image"

const Tile = styled.div`
  flex-basis: 100%;
  padding: 0rem;
  margin-top: 4rem;

  :hover {
    opacity: 0.5 !important;
    transition-duration: 0.7s;
  }

  opacity :nth-child(1) {
    margin-top: 0rem;
  }

  @media (min-width: 576px) {
    padding: 1rem;
    margin-top: 0rem;
    flex-basis: calc(33.3% - 2rem);
  }
`

const Image = styled.div`
  height: 250px;
  background-color: #d1d1d1;
`

const Information = styled.div`
  margin-top: 2rem;

  a {
    color: ${props => (props.textColor ? props.textColor : "white")};
    text-decoration: none;
  }
`

const Date = styled.div`
  margin-top: 3rem;
  color: ${props => (props.textColor ? props.textColor : "white")};
`

export default props => {
  let data = props.data

  return (
    <Tile className="related-tile">
      <Link to="#">
        <Image />
      </Link>
      <Information textColor={props.textColor}>
        <Link to="#">
          <h3>{data.title}</h3>
        </Link>
      </Information>
      <Date textColor={props.textColor} className="date">
        {data.date}
      </Date>
    </Tile>
  )
}
