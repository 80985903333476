import React, { useEffect } from "react"
import styled from "styled-components"

import ScrollReveal from "scrollreveal"

import RelatedTile from "./related-tile"

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 1.5rem 6rem 1.5rem;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "#101014"};

  @media (min-width: 576px) {
    padding: 0 3rem 4rem 3rem;
  }
`

const ContainerInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
`

const RelatedContent = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: ${props => (props.textColor ? props.textColor : "white")};

  @media (min-width: 576px) {
    padding-top: 3rem;
    padding-left: 1rem;
  }
`

export default props => {
  let data = props.data

  useEffect(() => {
    ScrollReveal({
      //   reset: true,
      delay: 1,
      distance: "30px",
    }).reveal(".related-tile")
  }, [])
  return (
    <Container backgroundColor={props.backgroundColor}>
      <RelatedContent textColor={props.textColor}>
        <h2>Related Content</h2>
      </RelatedContent>
      <ContainerInner>
        {data.map(data => (
          <RelatedTile data={data} textColor={props.textColor} />
        ))}
      </ContainerInner>
    </Container>
  )
}
